\.App {
  height: 100%;
}

html,
body,
#root {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --amplify-primary-color: #3182ce;
  --amplify-primary-tint: #2c5282;
  --amplify-primary-shade: #1a365d;

  /* --amplify-font-family
--amplify-text-xxs
--amplify-text-xs
--amplify-text-sm
--amplify-text-md
--amplify-text-lg
--amplify-text-xl
--amplify-text-xxl
--amplify-primary-color	
--amplify-primary-contrast	
--amplify-primary-tint	
--amplify-primary-shade	
--amplify-secondary-color	
--amplify-secondary-contrast	
--amplify-secondary-tint
--amplify-secondary-shade	
--amplify-tertiary-color	
--amplify-tertiary-contrast	
--amplify-tertiary-tint	
--amplify-tertiary-shade	
--amplify-grey	
--amplify-light-grey
--amplify-white
--amplify-red */
}

.swiper-slide {
  width: 400px !important;
}

.modal-slide .swiper-slide {
  width: 848px !important;
}

.slide-item {
  border: 1px solid rgb(200, 200, 200);

}

.slide-item p {
  background-color: rgba(100, 100, 100, 0.3);
  text-align: center;
  font-size: 15px;
  height: 80px;
  color: black;
  padding: 4px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-section {
  width: 848px !important;
  max-width: 100vw !important;
}

.slide-item img {
  width: 100%;
  /* height: 300px; */
  /* object-fit: cover; */
}

.graphiql-un-styled * {
  box-sizing: content-box !important;
}
.graphiql-un-styled *::after,
.graphiql-un-styled *::before {
  box-sizing: content-box !important;
}


.paginator-container nav {
  width: unset;
}
.paginator-container nav>button {
  margin: 0 10px;
  width: 36px;
}
/* comment to push */

.editor textarea {
  display: none !important;
}
.editor pre {
  white-space: pre !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}